.page403 {
    position: fixed;
    top: 50%;
    left: calc(50% - 160px);
    margin-top: -50px;
    margin-left: -100px;
}
.custom-css-for-float{
    left: calc(50% - 160px) !important;
}
.align-item-center {
    text-align:center;
}

.remove-text-decpration{
    text-decoration: none !important;
}
.color-message {
    color: #0000007d	;
}

.align-margin {
    margin: 10px;
}