.uppercase {
    text-transform: uppercase;
}
.margin-top-10{
    margin-top: 10px !important;
}
.padding-left-15px{
    padding-left: 15px !important;
}
.custom-border .padding-left-15px{
    padding-left: 35px !important;
}

.last-row-highlight .centered-align {
    font-weight: 700;
    background: #e8e4e4;
}
.font-weight-700 td:nth-child(2) {
    font-weight: 700;
}