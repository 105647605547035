.list-item {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.button-box {
  float: right;
  padding: 10px;
}

.download-button {
  margin: 5px !important;
  text-transform: none !important;
}
