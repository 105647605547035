.col-data-100{
    width: 100%;
    float: left;
    margin-top: 10px;
}
.margin-0{
    margin: 0 !important;
}
.dsp-inline-block{
    display: inline-block;
}
.btn-group{
    display: block;
    padding-left: 315px;
    padding-top: 25px;
    clear: both;
}
.overflow-visible{
    overflow: visible !important;
}
.search-policy.margin-left-100px{
    margin-left:-100px;
}
.margin-right-20px{
    margin-right: 20px !important;
}
.col-data-100 .margin-top-right-form{
    margin-top: 10px;
    margin-right: 15px;
    min-width: 200px;
    vertical-align: top;
}
.col-data-100 .date-align-text{
    margin-top: 35px;    
}
.padding-left-15px{
    padding-left: 15px;
}
.float-align-col{
    float: left;
    margin-right: 60px;
}