html {
  overflow: hidden;
}
.dsp-flex {
  display: flex;
  min-height: 100vh;
}
.dsp-flex header {
  width: 100% !important;
}

.minus-sign {
  padding: 10px;
  vertical-align: -webkit-baseline-middle;
  color: rgba(0, 0, 0, 0.87);
  font-size: 2.125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.margin-top-30 {
  margin-top: 30px;
}
.padding-10 {
  padding: 10px;
}
.main-container {
  height: 100vh;
  overflow: auto;
  margin: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.search-wrapper .text-field {
  min-width: 195px;
  margin: 0 20px;
}

.max-height-300 {
  max-height: 300px;
}
.max-height-400 {
  max-height: 400px;
}
.max-height-450 {
  max-height: 450px;
}

.vertical-scroll {
  position: sticky;
  top: 0px;
  background-color: #3f51b5;
  z-index: 9;
}
.centered-align {
  text-align: center !important;
  padding: 0 5px !important;
}

.text-header-style {
  text-align: center !important;
  padding: 0 8px !important;
  text-transform: capitalize;
  clear: both;
  overflow: hidden;
  white-space: nowrap;
}
.text-header-style svg {
  display: none;
}

.last-row .centered-align {
  font-weight: 700;
  position: sticky;
  bottom: 0px;
  background: #e8e4e4;
}
.text-align-left {
  text-align: left !important;
  padding-left: 20px !important;
}

h3 {
  text-align: center;
  font-weight: 400;
}

