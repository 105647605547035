.margin-top-15 {
    margin-top: 15px !important;
    width: 40px !important
}

.width-50-per {
    width: 50% !important;
}

.margin-10px {
    margin: 20px !important;
}

.download-button-style {
    float: right;
    margin: 10px !important;
}

.width-600px{
    width: 600px !important;
}
.width-700px{
    max-width: 700px !important;
}