.div-left {
    float: left !important;
    width: 50%;
}

.div-right {
    float: right !important;
    width: 50%;
}

.text-link-click input{
    cursor: pointer;
    color: #00f!important;
}

.margin-top-bottom-10px {
    margin: 20px  0px !important;
}

.button-right-position {
    margin : 10px 0px !important;
    float: right !important;
}

.div-margin {
    margin: 30px 0px;
}

.text-align-right {
    text-align: right;
}