.width-for-lable {
    width: 400px;
    margin: 20px 0px 0px 50px !important;
}


.caja-h3 {
    margin: 20px 0px !important;
    text-align: left;
}

.margin-top-right-form {
    margin: 30px 80px 0px 0px !important;
    width: 120px !important;
}
.width-118px{
    width: 160px;
}
.margin-left-form-Usuario {
    margin-left: 34px !important;

}
.margin-left-form {
    margin-left: 40px !important;
}


.margin-top-30px {
    margin-top: 30px !important;
}

.width-label {
    width: 150px;
}

.margin-left-100px {
    margin-left: 100px;
}

.margin-top-bottom-caja {
    margin: 35px 0px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.submit-button-center {
    text-align: center;
    margin: 20px !important;
}

.drop-down-menu-1 {
    display: -webkit-inline-box  !important;
    position: relative;
    z-index: 99;
}

.drop-down-menu-2 {
    display: -webkit-inline-box  !important;
    position: relative;
    z-index: 98;
}

.drop-down-menu-3 {
    display: -webkit-inline-box  !important;
    position: relative;
    z-index: 97;
}

.drop-down-menu-4 {
    display: -webkit-inline-box  !important;
    position: relative;
    z-index: 96;
}

.z-index-95 {
    z-index: 95;
    position :relative;
}

.center-div {
    text-align: center;

}

.download-button-title {
    display: -webkit-inline-box;
}

.download-button-css {
    position: absolute;
    right: 60px;
    margin-top: 20px !important;
}

.font-25px {
    font-size: 25px;
}