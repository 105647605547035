.padding-left-20{
    padding-left: 20px;
}
.align-top{
    margin-top: 13px;
    min-width: 135px;
}
.centred-aligned{
    margin: 20px 0;
    margin-left: 200px;
}
.siniestro-text{
    margin-top:10px;
}
.policy-item-group{
    margin-top: 15px;
}
.margin-top-bottom-0{
    margin-top:0 !important; 
    margin-bottom:0 !important; 
}
// #custom-css-standard >div{
//     width: 100px;
// }

.volver-style {
    display: inline-block;
    width: 100%;
}

.left-block {
    float: left;
    font-size: 24px;
}

.float-right-button {
    float: right;
}