@import '../Fonts/font';


.h1 {
	font-size: 24px!important;
	line-height: 45px!important;
  margin: 0px;
  font-family: 'Gotham-Medium'!important;
  &.gothamlight{
    font-family: 'Gotham-Light'!important;
  }
}

.h2 {
   font-size: 14px!important;
   line-height: 19px!important;
   margin: 0px;
   font-family: 'Gotham-Book'!important;
}

.b1 {
    font-size: 0.75rem!important;
    line-height: 15px!important;
    margin: 0px;
    font-family: 'Gotham-Bold'!important;
}

.b2 {
    font-size: 0.75rem!important;
    line-height: 15px!important;
    margin: 0px;
    font-family: 'Gotham-Book'!important;
}
