.main-container {
    width: 100%;
    padding: 40px;
    padding-top: 100px;
    margin: 20px 0;
}
.margin-right-15{
    margin-right: 15px;
}

h3, .label-style input{
    text-align: center;
}
.align-right{
    float: right;
}
.align-right .search-wrapper{
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 16px;
}
.text-font {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.75rem;
}
.inline-blk{
    display: inline-block;
    width: 100%;
}
.heading-style {
    padding: 10px;
}

.card-style {
    width: 100%;
    margin: 2px 0 20px 0;
}

.custom-border{
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: groove;
    border-color: rgba(0, 0, 0, 0.17);
    border-image: initial;
}
.custom-border legend.legend-box{
    display: block;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    font-size: 16px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    width: auto !important
}

.dailog-title-style {
    text-align: center;
}

.button-position {
    margin-top: 20px !important;
}

.button-item-position{
    //float: right;
}

.headign-position {
    margin: 10px !important;
}

.heading-h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.button-position-style {
    float: right;
    margin: 10px;
}
.search-wrapper .add-position-style{
    position: absolute;
    top: 10px;
    margin-left: 640px !important;

}
.search-wrapper .position-top-textbox{
    position: absolute;
    top: -6px;
    right: 190px;
}
.search-wrapper .position-top-textbox input{
    padding-top: 14.5px;
    padding-bottom: 14.5px;
}
.Card-area{
    position: relative;
}
.label-style{
    color: #000c !important;
    margin: 10px 15px 0;
    display: block !important;
}

.right-margin-space {
    margin-left: 672px !important;
}
.vertical-align-middle span, .vertical-align-middle div{
    vertical-align: middle;
}
.heading-text-style {
    text-align: center;
    padding: 10px;
    font-size: 20px !important;
    color: #00000091;
}
.usecase-11, .margin-bottom-20{
    margin-bottom: 20px;
}
.margin-top-20px{
    margin-top: 20px;
}

.space {
    margin: 20px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}
  
.center-text {
    text-align: center !important;
}

.padding-space {
    padding: 50px 0px;
}

.under-line {
    text-decoration: underline;
}