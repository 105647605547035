.button-motor {
    margin: 52px 5px  !important;

}
.remove-border{
    border: none;
}
.mb-0{
    margin-bottom: 0;
}
.text-margin-potento {
    margin: 40px 5px !important;
}