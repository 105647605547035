.space-left-right-10px {
  padding: 0px 20px !important;
}

.padding-10px {
  padding: 20px !important;
}

.busque-label {
  margin-top: 22px !important;
  padding: 10px !important;
}

.align-right-button {
  text-align: center;
}

.padding-5px {
  padding: 5px !important;
}

.space-for-calender {
  padding : 0px 20px !important;
}

.search-reaseguro {
  margin: 25px 10px 0px 10px !important;
}

.button-margin{ 
  margin: 25px !important;
}

.div-inline {
  display: -webkit-inline-box;
}

.labe-margin {
  margin-top: 30px !important;
  width: 200px;
}

.center-div {
  text-align: center;
}

.margin-top-40px {
  margin-top: 40px !important;
}
.no-border-style{
border: none;
box-shadow: none !important;
}
.border-style{
border: 1px solid #dee2e6 !important;
box-shadow: none !important;
}
.headerStyle{
  background-color: #d3d3d32b;
  text-align: center;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.row-border{
  border-right:1px solid #dee2e6;
  border-left:1px solid #dee2e6;
  border-bottom:1px solid #dee2e6; 
 
}

.row-border:first-child{
  border-top:1px solid #dee2e6    
} 

.border-right{
border-right:1px solid #dee2e6
}

.border-top{
border-top:1px solid #dee2e6
}

.border-bottom{
  border-bottom:1px solid #dee2e6
}

.box {
  border: 1px solid #dee2e6;
}
.align-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.coloumn-style{
  padding: 10px 0;
  text-align: center;
}
.pull-right{
float:right
}
.tab {
  margin-top: 3%;
}

.tab-container {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 0px !important;
}
.tab-class .padding-left-15px {
  padding-left: 35px !important;
}
.tab-button {
  outline: none !important;
}

.header-class {
  text-align: center;
  font-weight: 400;
  margin: 0;
}

.padding-round{
  padding-top:10px;
  padding-bottom: 10px; 
  &.padding-larger-round{
    padding-top:20px;
  padding-bottom: 15px; 
  }
}

.check-box-align{
  padding-top:22px
}

.col-data-100 .date-align-text{
  margin-top: 35px;  
  margin-left: 20px;
  min-width: 200px;  
}
.reso-btn-group{
  padding: 0;
  display: block;
  float: right;
  margin: 0 0 25px 0;
}
.reso-btn-group .btn{
display: inline-block;
}

.custom-scroll-style td,.custom-scroll-style th { 
border: 1px solid #dee2e6 !important; 
border-collapse: collapse; 
padding: 6px; 
text-align: center;
} 
.custom-scroll-style td.no-border,.custom-scroll-style th.no-border{
border: none !important;
}
.custom-scroll-style{
margin-bottom: 30px;
padding: 30px 0;
overflow: auto;
}

.right-align{
text-align: right;
}
.col-highlight{
background:#3f51b5;
color: #fff;
}
.grey-background{
background: #eee;
}


