.no-padding{
    padding: 0;
}
.paddingtop-10{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.font-16{
    font-size: 15px !important;
}
.font-weight-600{
    font-weight: 600 !important;
}
.toolbar-icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    // ...theme.mixins.toolbar;
}
.side-navigation{
    list-style-type:none;
    padding-top:20px;
    text-decoration:none !important;
    padding-left:0;
    text-align:center;
  }

// .search-wrapper{
//     margin:50px;
// }
.search-wrapper .text-field{
    margin: 0 20px;
}
.search-wrapper .search-policy{
    vertical-align: bottom !important;
    margin-left: 25px;
}
.button-style{
    width: 100%;
    outline: none;
    border: none;
    background: none;
    padding: 10px;
}
.active-link{
    background-color: #3f51b5;
}
.navigation-style{
display: block;
text-decoration:none;
}
.active-link button{
    color: #fff;
    outline-color: transparent;
    background-color: transparent;
}
.active-link button:hover{
    background-color: transparent;
}
.logo{
max-height: 50px;
max-width: 170px;
}
.PersonIcon{
   margin-left: 40px;
   margin-right:20px;
}
.text-decoration-none{
    text-decoration: none !important;
    display: block;
    margin: 1px 0;
}

.paddingtop-10:hover,.paddingtop-10:focus{
    background-color: transparent !important;
}
.text-decoration-none.active{
    background-color:rgb(221, 221, 221);   
}
.text-decoration-none.active span,.text-decoration-none.active .font-white{
   color: #fff;
}


.sub-menu-style {
    word-break: break-word !important;
    margin-left: 10px;
}

.letter-spacing {
    letter-spacing: 1px;
}
.overflow-y-auto{
    height: 100vh;
    overflow: auto;
}
