.header{
	background:#f4f4f4;
}

.logo{
    max-height: 50px;
    max-width: 170px;
}

.brand-logo{
	width: 120px;
	height: 35px;	
}

.navbar{
	background: #FFF;
	padding: .5rem 4rem!important;
}
.navbar{
	.dropdown{
		span.fa{
			font-size: 40px;
		}
		.dropdown-toggle::after{
			vertical-align:middle;
		}
		.fa-custom-button:hover{
			color:#4BD800;
		}
	}
	.username{
		color: #393939;
    	font-family: Gotham-Book;
	}
	.user-profile{
		color: #354052;
    	font-family: Gotham-Light;
    	font-size: 14px;
    	line-height: 12px;
	}
}


.nav-item{
	display: table;
	.user-icon, .user-details{
		display: table-cell;
		vertical-align: middle;
		padding: 0 5px; 

	}
	.user-details{
		span{
			display: table-row;
		}
	}
}
.fa-user-o{
	font-size: 20px !important;
	border: 1px solid;
	padding: 10px 12px;
	border-radius: 40px;
}
.remove-fcs:focus{
	outline: none;
}
.translate-btn-grp{
	margin-right: 25px;
}
.translate-btn-grp .remove-fcs{
	margin: 0 7px;
	text-transform: none;
}
.remove-fcs.lng-selected-button{
	background-color: rgba(0, 0, 0, 0.08);
}
.color-white{
	color: #fff !important;
}
.remove-padding{
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.custom-property{
	margin-left: 12px;
	min-height: 48px !important;
	padding: 0 !important;
}
#menu-appbar li:focus{
	background-color: transparent !important;
}
#menu-appbar li:hover{
	text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08) !important;
}


.userName{
	font-style: italic;
}
.profilemenu div:nth-child(2){
	top: 65px !important;
}