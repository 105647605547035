.uppercase {
  text-transform: uppercase;
}
.default-color {
  color: rgba(0, 0, 0, 0.87);
}

.tableHeader {
  background-color: rgb(63, 81, 181);
}
.tableHeaderText {
  color: white !important;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.hyper-link-color {
  color: blue !important;
  text-decoration: underline;
}

.last-row .centered-align {
  font-weight: 700;
  position: sticky;
  bottom: 0px;
  background: #e8e4e4;
}

.click-link-style {
  cursor: pointer;
  color: blue !important;
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}
