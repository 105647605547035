.page-loader {
	 position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0;
    overflow-y: auto;
    background-color: rgba(0,0,0,.45);
    z-index: 10000;
    opacity: 1;
    transition: opacity .3s;

		.loader-text{
			font-family: 'Gotham-Book';
			position: absolute;
			top: 60%;
			left: 50%;
			color: #4113B9;
		}

		$animation-duration: 1.8s;

		.loader {
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  width: 50px;
		  height: 10px;
		  background:white;
		  border-radius: 5px;
		  animation: load $animation-duration ease-in-out infinite; 
		  &:before, &:after {
		    position: absolute; 
		    display: block;
		    content: "";
		    animation: load $animation-duration ease-in-out infinite;
		    height: 10px;
		    border-radius: 5px;
		  }
		    &:before {
		    top: -20px;
		    left: 10px;
		    width: 40px;
		    background: #4113B9;
		  }
		  &:after {
		    bottom: -20px;
		    width: 35px;
		    background: #4113B9; 
		  }
		}

		@keyframes load {
		  0% {
		    transform: translateX(40px);
		  }
		  
		  50% {
		    transform: translateX(-30px);
		  }
		  100% {
		    transform: translateX(40px);
		  }
		}

	}