.empty-state-container {
  width: 100%;
  height: 400px;
  position: relative;
  margin-top: 60px;
  .floating-message {
    top: 25%;
    left: 36%;
    position: absolute;
    float: none;
    margin: 0;
    text-align: center;

    .emoticon {
      font-size: 55px;
      font-weight: bold;
      color: black;
      font-family: "GothamLight";
      padding-bottom: 20px;
    }

    .message {
      font-size: 20px;
      color: grey;
      font-family: "GothamLight";
    }
  }
}
