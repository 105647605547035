.Card-area{
    width: 100%;
}
.margin-10{
    margin: 10px 0;
}
.button-space{
    padding: 10px;
}

.text-field-space{
    width: 450px;
}

.claim-by-policyno {
    padding: 20px;
}

.card-space{
    margin: 15px 0 15px 0;
    padding: 10px 0 10px 0;
}

.main-screen{
    padding: 20px;
}

.space-for-card {
    padding: 10px;
}

.selected-button {
    background-color: whitesmoke !important;
    color: black !important;
}

.margin-for-table {
    margin: 10px;
}