.main-container {
    padding-top: 100px;
    width: 100%;
}

h3{
    text-align: center;
}

.collection-button {
    float: right;
}

.collection-button-space {
    margin: 10px !important;
}

.space-for-fieldset {
    padding-top: 10px;
}