// style={{ overflow: "scroll", maxHeight: "440px" }}

.coaseguro {
  overflow: scroll;
  max-height: 460px;
}

.compania-lider {
  display: inline-block;
}

.poliza-lider {
  display: inline-block;
  margin-left: 30px;
}

.tab-class {
  min-height: 460px;
  padding-left: 20px;
  padding-right: 20px;
}
