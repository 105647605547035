.text-field-space{
    padding: 2px;
}

.lastSection {
	width:20px;
}
.remove-padding .Space-around{
    padding: 0;
}
.vertical-align-middle span, .vertical-align-middle div{
    vertical-align: middle;
}
.remove-shadow{
    box-shadow: none !important;
    border:1px solid rgba(0, 0, 0, 0.12);
}