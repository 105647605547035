
.home-page {
    padding: 32px 60px !important;
  }
  .side-navigation {
    list-style-type: none;
    padding-top: 20px;
    text-decoration: none !important;
    padding-left: 0;
    text-align: center;
  }
  
  // .search-wrapper{
  //     margin:50px;
  // }
  
  .search-wrapper .text-field {
    margin: 0 20px;
  }
  .search-wrapper .search-policy {
    vertical-align: bottom !important;
    margin-left: 25px;
    text-transform: none;
  }
  .button-group .search-policy {
    margin-bottom: 15px;
  }
  .search-wrapper.button-group {
    margin-bottom: 10px;
    text-align: center;
  }
  .button-style {
    width: 100%;
    outline: none;
    border: none;
    background: none;
    padding: 10px;
    text-decoration: none;
  }
  .active-link {
    background-color: #3f51b5;
  }
  .navigation-style {
    display: block;
    text-decoration: none;
  }
  .active-link button {
    color: #fff;
    outline-color: transparent;
    background-color: transparent;
  }
  .active-link button:hover {
    background-color: transparent;
  }
  .logo {
    max-height: 50px;
    max-width: 170px;
  }
  .PersonIcon {
    margin-left: 40px;
    margin-right: 20px;
  }
  .breadcrum {
    width: 100%;
    height: 50px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
    padding: 6px 24px 6px 24px;
    position: relative;
    z-index: 1;
  }
  
  .selectClass {
    width: 19%;
    display: inline-block;
    padding-top: 10px;
    margin: 0 20px;
  }
  