.mainComponent {
  padding-top: 8%;
  width: 100%;
}

.commonClass {
  margin: 20px;
  padding: 20px;
}

.porClineteHeader {
  background: #3f51b5;
  color: #fff;
  font-weight: 400;
  padding-left: 10px;
}

.porClenteInp {
  margin-right: 25px !important;
  margin-top: 0 !important;
}

.firstDiv {
  width: 200px;
  float: left;
}

.secondDiv {
  width: 600px;
  margin-left: 200px;
}

.tFirstDiv {
  width: 300px;
  margin-left: 15px;
  float: left;
  height: 150px;
}

.tSecondDiv {
  width: 300px;
  margin-left: 320px;
  height: 150px;
}

.tThirdDiv {
  width: 200px;
  margin-left: 620px;
  height: 150px;
}

.thirdSection {
  padding: 20px;
}

.textField {
  padding: 20px;
}

.BuskarLink {
  float: right;
  margin-right: 25px;
  padding-top: 25px;
}

.porClientRowClass {
  margin-top: 15px;
  margin-bottom: 15px;
}

.resetButtonClass {
  float: right;
  /* padding-right: 44px; */
  margin-top: 25px !important;
}

.search-icon {
  margin-top: 10px !important;
}

.label-class {
  margin-bottom: 10px;
  margin-top: -7px;
  display: block;
}

.download-button {
  margin: 15px;
}

.download-icon {
  padding-right: 5px;
}

.MuiButtonBase-root-27 {
  outline: none !important;
}
