.drop-down-menu {
    display: -webkit-inline-box  !important;
    position: relative;
    z-index: 99;
}
.font-14px p{
    font-size: 15px;
}
// .width450px{
//     width: 450px;
// }
.select-style {
    margin: 12px 10px 0px 0px; 
}