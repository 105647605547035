.range-group {
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
  margin: 4px;
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;
}
.margin-right-20 {
  margin-right: 20px !important;
  vertical-align: middle !important;
}
