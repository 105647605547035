.Main-container {
    display: flex;
    flex:wrap
}

.Space-around{
    padding: 10px;
}

.Card-area{
    width: 100%;
}

.minus-sign{
    padding: 10px;
    vertical-align: -webkit-baseline-middle;
}
.vertical-align-middle span, .vertical-align-middle div{
    vertical-align: middle;
}