@font-face {
  font-family: 'GothamRoundedBold';
  src: url('../Fonts/fonts/gothamrnd-bold.eot');
  src: url('../Fonts/fonts/gothamrnd-bold.eot') format('embedded-opentype'),
  url('../Fonts/fonts/gothamrnd-bold.woff') format('woff'),
  url('../Fonts/fonts/gothamrnd-bold.ttf') format('truetype'),
  url('../Fonts/fonts/gothamrnd-bold.svg') format('svg');
}


@font-face {
  font-family: 'GothamRoundedBook';
  src: url('../Fonts/fonts/gothamrnd-book.eot');
  src: url('../Fonts/fonts/gothamrnd-book.eot') format('embedded-opentype'),
  url('../Fonts/fonts/gothamrnd-book.woff') format('woff'),
  url('../Fonts/fonts/gothamrnd-book.ttf') format('truetype'),
  url('../Fonts/fonts/gothamrnd-book.svg') format('svg');
}
@font-face {
  font-family: 'GothamRoundedMedium';
  src: url('../Fonts/fonts/gothamrnd-medium.eot');
  src: url('../Fonts/fonts/gothamrnd-medium.eot') format('embedded-opentype'),
  url('../Fonts/fonts/gothamrnd-medium.woff') format('woff'),
  url('../Fonts/fonts/gothamrnd-medium.ttf') format('truetype'),
  url('../Fonts/fonts/gothamrnd-medium.svg') format('svg');
}
@font-face {
  font-family: 'GothamRoundedLight';
  src: url('../Fonts/fonts/gothamrnd-light.eot');
  src: url('../Fonts/fonts/gothamrnd-light.eot') format('embedded-opentype'),
  url('../Fonts/fonts/gothamrnd-light.woff') format('woff'),
  url('../Fonts/fonts/gothamrnd-light.ttf') format('truetype'),
  url('../Fonts/fonts/gothamrnd-light.svg') format('svg');
}

@font-face {
  font-family: 'SFDisplay-Thin';
  src: url('../Fonts/fonts/SFDisplay-Thin.otf');
}

@font-face {
  font-family: 'SFDisplay-Semibold';
  src: url('../Fonts/fonts/SFDisplay-Semibold.otf');
}

@font-face {
  font-family: 'SFDisplay-Regular';
  src: url('../Fonts/fonts/SFDisplay-Regular.otf');
}

@font-face {
   font-family: 'Gotham-Bold';
   src: url("../Fonts/fonts/Gotham-Bold.otf");
 }

 @font-face {
   font-family: 'Gotham-Book';
   src: url('../Fonts/fonts/Gotham-Book.otf');
 }
 
 @font-face {
   font-family: 'Gotham-Light';
   src: url('../Fonts/fonts/Gotham-Light.otf');
 }

 @font-face {
   font-family: 'Gotham-Medium';
   src: url('../Fonts/fonts/Gotham-Medium.otf');
 }
