.textField {
    padding: 40px;
}

.Main-container {
    display: flex;
    flex:wrap
}

.Space-around{
    padding: 10px;
}

.Heading-name {
 text-align: center;
 //padding: 10px;
 font-size: 24px;
 color: #00000091;
}

.Space-around{
    padding: 10px;
}

.Policy-detail-container{
    align-items: center;
}

.box-show-none {
    box-shadow: none !important;
}