
.main-container {
    padding-top: 100px;
    width: 100%;
}

h3{
    text-align: center;
}

.search-policy{
	text-transform:none !important;
}

.root{
    display: 'flex',
}

.main { 
    width: 800px;
    margin: 0 auto;
}

.sidebar {
    width: 40%;
    margin-top:20px;
    padding-top:20px;
    float: left;
}

.page-wrap  {
    width: 60%;
    margin-left: 40%;
}

.input-box {
	width: 82%;
    margin-top: 18% !important;
}

.search-wrapper{
	margin-bottom: 40px;
}